<template>
    <v-hover v-slot="{ hover }">
        <v-navigation-drawer
            v-model="navDrawer"
            :mini-variant="mini"
            permanent
            temporary
            hide-overlay
            app
            style="box-shadow:none"
            v-click-outside="onClickOutside"
        >
            <template v-slot:prepend>
                <div
                    style="position:relative; width:56px; min-height: 80px;"
                    @click.stop="mini = !mini">
                    <img
                        src="/lea-logo.svg"
                        :width="$vuetify.application.left"
                        style="padding: 7px;margin: 13px 0; width:56px; height: 40px;"
                    />
                    <v-btn v-show="hover && mini" plain depressed style="position: absolute; top:52px; left:-8px; padding:0;"><v-icon color="primary">mdi-chevron-double-right</v-icon></v-btn>
                </div>
            </template>
            <v-list>
                <v-list-item ripple exact :to="{ name: 'Home' }" @click="displayDashboardBadge = false">
                    <v-list-item-action>
                        <v-badge
                            :value="displayDashboardBadge"
                            dot
                            bordered
                            color="warning"
                        >
                            <v-icon :class="{ 'primary--text': $route.name === 'Home' }" v-text="'mdi-home-outline'" />
                        </v-badge>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="$t('navigation_items.home')"
                            :class="{ 'primary--text': $route.name === 'Home' }"
                        ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <template v-for="(item, i) in appItems">
                    <NavItem :item="item" :key="i" />
                </template>
                <v-list-item v-if="$devMode" disabled>
                    <v-list-item-action>
                        <v-icon class="warning--text"
                            >mdi-xml</v-icon
                        >
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="'dev mode'"
                            class="warning--text"
                        ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list>
                    <v-list-item
                        v-if="getEventDataByKey('disableApiVersionConflictDialog')"
                        @click="reloadPage()"
                        ripple
                    >
                        <v-tooltip
                            top
                            color="warning"
                            v-if="
                                getEventDataByKey('disableApiVersionConflictDialog')
                            "
                        >
                            <template v-slot:activator="{ on }">
                                <v-list-item-action v-on="on">
                                    <v-icon color="warning">refresh</v-icon>
                                </v-list-item-action>
                                <v-list-item-content v-on="on">
                                    <v-list-item-title
                                        v-text="
                                            $t('apiVersionConflictDialog.title')
                                        "
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <span>{{
                                $t('apiVersionConflictDialog.message')
                            }}</span>
                        </v-tooltip>
                    </v-list-item>
                    <v-list-item ripple :to="{ name: 'Settings' }">
                        <v-list-item-action>
                            <v-icon
                                :class="{
                                    'primary--text': $route.name === 'Settings',
                                }"
                                >mdi-cog-outline</v-icon
                            >
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="$t('navigation_items.settings')"
                                :class="{
                                    'primary--text': $route.name === 'Settings',
                                }"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$hasRole('ROLE_MASTER_EDITOR') || $hasRole('ROLE_SUPER_ADMIN')" ripple :to="{ name: 'Admin' }">
                        <v-list-item-action>
                            <v-icon>mdi-wrench-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="$t('navigation_items.admin')"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="!$ssoMode" ripple :to="{ name: 'Logout' }">
                        <v-list-item-action>
                            <v-icon>js-icon-log-out</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="$t('navigation_items.logout')"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
    </v-hover>
</template>

<script>
import NavItem from './navItem.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'layoutMainNavigation',
    components: {
        NavItem
    },
    props: {
        value: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            displayDashboardBadge: false,
            mini: true
        }
    },
    computed: {
        ...mapGetters({
            appItems: 'apps/getApps',
            getEventDataByKey: 'eventStore/getDataByKey',
            consultantTaskCount: 'dashboard/getConsultantTaskCount',
        }),
        navDrawer: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        }
    },
    methods: {
        changeThemeMode() {
            this.$emit('toggleThemeMode');
        },
        onClickOutside() {
            this.mini = true;
        },
        reloadPage() {
            this.$router.go({
                path: this.$router.path,
                query: {
                    t: +new Date(),
                },
            });
        },
    },
    watch: {
        consultantTaskCount (newVal, oldVal) {
            if (this.$hasRole('ROLE_CONSULTANT') && newVal > oldVal) {
                this.displayDashboardBadge = true;
            }
        }
    }
};
</script>
