<template>
  <v-list-item ripple :to="item.route">
      <v-list-item-action>
          <v-icon
              :class="{
                  'primary--text':
                      $route.name === item.route.name,
              }"
              v-text="item.icon"
          ></v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title
              v-text="
                  $t(
                      'navigation_items.' +
                          getTranslationName(item.route.name)
                  )
              "
              :class="{
                  'primary--text':
                      $route.name === item.route.name,
              }"
          ></v-list-item-title>
      </v-list-item-content>
  </v-list-item>
</template>

<script>
import camelCase from 'lodash/camelCase';

export default {
    name: 'layoutMainNavigationItem',
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    methods: {
        getTranslationName(val) {
            return camelCase(val);
        }
    }
}
</script>