var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-navigation-drawer',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticStyle:{"box-shadow":"none"},attrs:{"mini-variant":_vm.mini,"permanent":"","temporary":"","hide-overlay":"","app":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticStyle:{"position":"relative","width":"56px","min-height":"80px"},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('img',{staticStyle:{"padding":"7px","margin":"13px 0","width":"56px","height":"40px"},attrs:{"src":"/lea-logo.svg","width":_vm.$vuetify.application.left}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(hover && _vm.mini),expression:"hover && mini"}],staticStyle:{"position":"absolute","top":"52px","left":"-8px","padding":"0"},attrs:{"plain":"","depressed":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-double-right")])],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-list',[(_vm.getEventDataByKey('disableApiVersionConflictDialog'))?_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.reloadPage()}}},[(
                            _vm.getEventDataByKey('disableApiVersionConflictDialog')
                        )?_c('v-tooltip',{attrs:{"top":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-list-item-action',_vm._g({},on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("refresh")])],1),_c('v-list-item-content',_vm._g({},on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                                        _vm.$t('apiVersionConflictDialog.title')
                                    )}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('apiVersionConflictDialog.message')))])]):_vm._e()],1):_vm._e(),_c('v-list-item',{attrs:{"ripple":"","to":{ name: 'Settings' }}},[_c('v-list-item-action',[_c('v-icon',{class:{
                                'primary--text': _vm.$route.name === 'Settings',
                            }},[_vm._v("mdi-cog-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
                                'primary--text': _vm.$route.name === 'Settings',
                            },domProps:{"textContent":_vm._s(_vm.$t('navigation_items.settings'))}})],1)],1),(_vm.$hasRole('ROLE_MASTER_EDITOR') || _vm.$hasRole('ROLE_SUPER_ADMIN'))?_c('v-list-item',{attrs:{"ripple":"","to":{ name: 'Admin' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-wrench-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('navigation_items.admin'))}})],1)],1):_vm._e(),(!_vm.$ssoMode)?_c('v-list-item',{attrs:{"ripple":"","to":{ name: 'Logout' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("js-icon-log-out")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('navigation_items.logout'))}})],1)],1):_vm._e()],1)]},proxy:true}],null,true),model:{value:(_vm.navDrawer),callback:function ($$v) {_vm.navDrawer=$$v},expression:"navDrawer"}},[_c('v-list',[_c('v-list-item',{attrs:{"ripple":"","exact":"","to":{ name: 'Home' }},on:{"click":function($event){_vm.displayDashboardBadge = false}}},[_c('v-list-item-action',[_c('v-badge',{attrs:{"value":_vm.displayDashboardBadge,"dot":"","bordered":"","color":"warning"}},[_c('v-icon',{class:{ 'primary--text': _vm.$route.name === 'Home' },domProps:{"textContent":_vm._s('mdi-home-outline')}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{ 'primary--text': _vm.$route.name === 'Home' },domProps:{"textContent":_vm._s(_vm.$t('navigation_items.home'))}})],1)],1),_vm._l((_vm.appItems),function(item,i){return [_c('NavItem',{key:i,attrs:{"item":item}})]}),(_vm.$devMode)?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"warning--text"},[_vm._v("mdi-xml")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"warning--text",domProps:{"textContent":_vm._s('dev mode')}})],1)],1):_vm._e()],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }