var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{attrs:{"ripple":"","to":_vm.item.route}},[_c('v-list-item-action',[_c('v-icon',{class:{
                'primary--text':
                    _vm.$route.name === _vm.item.route.name,
            },domProps:{"textContent":_vm._s(_vm.item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
                'primary--text':
                    _vm.$route.name === _vm.item.route.name,
            },domProps:{"textContent":_vm._s(
                _vm.$t(
                    'navigation_items.' +
                        _vm.getTranslationName(_vm.item.route.name)
                )
            )}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }